import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import CoverStatusItem from "../parts/CoverStatus";
import moment from "moment";


interface CoverPageProps {
  report: any;
}

const styles = StyleSheet.create({
  coverBody: {
    fontFamily: "CeraPro",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: 40,
  },

  coverLogosView: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  coverLogosLogo: {
    width: 100,
    height: 100,
    borderRadius: "10px",
    objectFit: "contain",
  },
  coverTitle: {
    fontSize: 36,
  },
  coverInspectionStage: {
    fontSize: 11,
    color: "#798286",
    fontWeight: "light"
  },
  coverSubtitle: {
    color: "#45505E",
  },
  twoRowStatus: {
    display: "flex",
    flexDirection: "row",
  },
  logoTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: "row",
    marginTop: 4,
    fontSize: 12,
    fontWeight: 'bold'
  },
  title: {
    fontFamily: "CeraProRegular",
    fontWeight: 900,
    color: "#798286",
    fontSize: 11,
  },
  content: {
    color: "#45505E",
    fontSize: 14,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 10,
    right: 30,
    fontSize: 12,
    fontFamily: 'CeraProRegular',
    color: "#798286",
  },
});

const CoverPage: React.FC<CoverPageProps> = ({ report }) => {
  return (
    <Page size="A4" style={styles.coverBody}>
      <View style={styles.coverLogosView}>        
        <View>
          <Image src="/images/logo.png" style={styles.coverLogosLogo} />
        </View>
        {report.company_logo !== '' ? <Image src={report.company_logo} style={styles.coverLogosLogo} /> : <View></View>}
      </View>
      <View style={{ width: '100%' }}>
        <Text style={styles.coverTitle}>{report.plot.name}</Text>
        <Text style={styles.coverSubtitle}>{report.plot.site.name}</Text>

        <View style={{ marginTop: "20px" }}>
          <Text style={styles.coverInspectionStage}>Inspection Stage</Text>
        </View>

        <View style={{ marginTop: "20px" }}>
          <CoverStatusItem
            title="Prepared By:"
            content={`${report.user.name} [${report.user.email}]`}
          />
        </View>

        <View style={{ marginTop: "20px" }}>
          <CoverStatusItem
            title="Date:"
            content={`${moment().format("DD/MM/YYYY")}`}
          />
        </View>


        <View style={{ marginTop: "20px" }}>
          <Text style={styles.title}>Snag Count:</Text>
          <Text style={styles.content}>Total Snags: {report.snagListCount + report.snagListResolvedCount}</Text>
          <Text style={styles.content}>Closed Snags: {report.snagListResolvedCount}</Text>
          <Text style={styles.content}>Open Snags: {report.snagListCount}</Text>
        </View>
      </View>

      <View>
        <Text style={styles.title}>Ref ID: {String(report.plot.id).padStart(5, '0')}</Text>
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `Page ${pageNumber} of ${totalPages}`
      )} fixed />
    </Page>
  );
};

export default CoverPage;
